

:root {
  --primary-color: #0b508b;
  --secondry-color: #f4a00f;
   --white-color: #fff;
  --black-color: #000;
  --black-color2: #1b1b1c;
  --black-color3: #212121;
  --black-color4: #313131;
  --lightgray-color: #e7e6e6;
  --gray-color: #a1a1a1;
  --gray-color2: #a5a4a4;
  --gray-color3: #e7e7e7;
  --facebook-color: #3b5998;
  --instagram-color: rgb(183, 39, 147);
  --youtube-color: #c4302b;
  --background-color: #fafafa;
  --background-color2: #e3e3e3;
}

*{
  margin: 0;
  padding: 0;
  box-shadow: none;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Lora', serif !important;

}

img {
  max-width: 100% !important;
}
a{
  text-decoration: none !important;
}

body {
  font-family: 'Lora', serif !important;
}

body,
html {
  scroll-behavior: smooth;
  font-family: 'Lora', serif !important;
}

.container{
  /* max-width: 100px; */
  position: relative;
}

/* topbar css start */
.topbar_section{
  background-color: var(--primary-color);
}
@media screen and (max-width: 768px) {
  .topbar_section {
    display: none;
  }
}
.left_topbar_div a{
  color: var(--white-color);
  font-size: 14px !important;
  margin: 0px 5px;
}

.right_topbar_div{
  text-align: right;
}

.right_topbar_div .fa-truck{
  transform: rotate(60deg);
  transform: scaleX(-1);
}

.cart_div .fa-cart-plus{
  transform: rotate(60deg);
  transform: scaleX(-1);
}

.right_topbar_div a{
  color: var(--white-color);
  font-size: 14px !important;
  margin: 0px 5px;
}

.right_topbar_div b{
  margin: 0px 15px;
  font-size: 14px;
  color: var(--white-color);
}

.right_topbar_div .fa-facebook{
  background-color: 	#1877F2;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}


.right_topbar_div .fa-instagram{
  background: linear-gradient(30deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5);
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}



.right_topbar_div .fa-youtube{
  background-color: var(--youtube-color);
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}
/* topbar css end */

/* logo div css */
.logo_section{
  padding: 5px 0px;
  border-bottom: 1px solid var(--background-color);
}

.logo_div img{
width: 150px;
}


.logo_flex_div{
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: space-between;
}

.logo_div_login{
  display: flex;
  align-items: center;
  gap: 5px;
}

.search_div_logo .input-group input{
  border-radius: 0px !important;
  box-shadow: none !important;
  outline: none !important;
  width: 500px;
  max-width: 100%;
}

.search_div_logo .input-group button{
  border-radius: 0px !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  padding: 0px 10px !important;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.logo_div_login .dropdown:hover .dropdown-menu{
  display: block;
}

.logo_div_login .dropdown .dropdown-menu{
  border-radius: 0px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  outline: none !important;
  border: 1px solid var(--background-color) !important;
  padding: 0px !important;
  background-color: var(--white-color) !important;
  z-index: 99999;
  
}

.logo_div_login .dropdown .dropdown-menu > li > a:hover{
  background-color: var(--background-color2) !important;
}

.logo_div_login .dropdown button{
  border-radius: 1px !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  background-color: var(--black-color4);
  color: var(--white-color);  
  padding: 8px 25px 5px 22px;
  font-size: 15px;
}

.logo_div_login .dropdown .dropdown-menu li{
  border-bottom: 1px dashed var(--gray-color3);
}

.logo_div_login .dropdown .dropdown-menu li a{
  font-size: 14px;
  font-weight: 600;
}

.cart_div a{
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 700;
  color: var(--black-color4);
  background-color: var(--background-color2);
  padding: 11px 25px 9px 23px;
}

.cart_div a .badge{
  background-color: var(--primary-color);
  border-radius: 100px !important;
}

.cart_div .fa-cart-plus{
 color: var(--black-color3);
 font-size: 20px;
}

/* end logo div css */

/* navbar css */

.phone_view_icons_div{
  display: none;
}

.phone_view_icons_div .phone_icons_nav{
background-color: var(--primary-color);
display: inline-block;
border-radius: 100px;
padding: 5px 10px 0px 10px;
}
.phone_view_icons_div .phone_icons_nav a{
  margin: 0px 3px;
  color: var(--white-color);
}
/* 
.phone_view_icons_div a{
    color: var(--black-color4);
  font-size: 17px;
} */

.phone_view_icons_div .badge{
  color: var(--white-color);
 
}

.phone_view_icons_div .offcanvas_btn{
  margin-left: 5px;
  font-size: 22px;
  color: var(--primary-color);
}

.navbar-brand{
  /* display: none; */
  width: 200px;
  
}

.navbar{
  background-color: var(--background-color) !important;
  padding: 0px !important;
  border-top: 1px solid var(--gray-color3);
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}
.dropdown:hover .dropdown-menu{
  display: block;
}


.navbar .navbar-nav > li > a{
  font-size: 14px;
  font-weight: 600;
  color: black !important;
  padding: 8px 15px !important;
  transition: .4s ease-in-out;

}

.navbar .navbar-nav > li > a:hover{
  color: var(--primary-color) !important;
}

.navbar-light .navbar-nav .nav-link{
  color: var(--black-color2) !important;
  padding: 8px 15px !important;
  font-size: 15px;
}

.categories_btn{
margin-bottom: 0px !important;
}
.categories_btn li {
  list-style: none;
}

.categories_btn li a{
 background-color: var(--primary-color);
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .3px;
padding: 6px 20px;

}

.search_bar_divmd .modal-content{
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  margin-top: 23%;
 
}

.poppup_searchbar .input-group input {
  margin-bottom: 0px;
  box-shadow: none !important;
  border-radius: 0px !important;
  outline: none !important;
  padding: 10px 15px 10px 10px;
  /* border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important; */
}

.poppup_searchbar .input-group-text {
  margin-bottom: 0px;
  box-shadow: none !important;
  border-radius: 0px !important;
  outline: none !important;
  background-color: var(--white-color) !important;
  padding-top: 10px !important;
  color: var(--black-color2);
  /* border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important; */
  font-size: 20px;
  font-weight: 600;

}

/* navbar css end */

/* mobile navbar css start */
.sidebar_main_div .offcanvas-header{
padding: 3px 10px !important;
background-color: #e8e8e8;
}

.sidebar_main_div .offcanvas-header h5 img{
width: 40px;
height: 40px;
line-height: 40px;
text-align: center;
border-radius: 100px;
background-color: var(--black-color2);
object-fit: cover;
border: 2px solid var(--white-color);
}

.sidebar_main_div .offcanvas-header h5 a{
  font-size: 13px;
  color: var(--white-color);
  text-transform: uppercase;
  align-items: center;

}

.sidebar_main_div .offcanvas-header{
  display: flex;
  justify-content: space-between;
}

.sidebar_main_div .offcanvas-header button{
 background: none;
 box-shadow: none !important;
 border: none !important;
 outline: none !important;
 box-shadow: none !important;
 color: black;
 font-size: 14px;
 width: 25px;
 height: 38px;
 line-height: 25px;
 text-align: center;
 border-radius: 100px;
}

.sidebar_main_div .offcanvas-body{
  padding: 7px 0px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--white-color) !important;
}


.sidebar_main_div .offcanvas{
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: var(--white-color) !important;
}

.sidenav_navlink_div > ul{
  list-style: none;
  padding: 0px;
  margin-bottom: 0px !important;
}

.sidenav_navlink_div > ul > li{
  border-bottom: 1px dashed var(--background-color2);
  padding: 4px 15px;
  
}

.sidenav_navlink_div > ul > li > a{
  color: var(--black-color2);
  font-weight: 600;
  font-size: 15px;
  display: block;
  text-transform: capitalize;
}


.categories_div_main > li{
  border-bottom: 1px dashed var(--background-color2);
  padding: 4px 15px;
  
}


.categories_div_main  > li > a{
  color: var(--black-color2);
  font-weight: 600;
  font-size: 15px;
  display: inline-block;
  text-transform: capitalize;
}

.categories_div_main  > li > span{
  float: right;
  cursor: pointer;
  font-size: 20px;
  padding: 3px 0px;
}

.categories_div_main  .categories_card_body{
  border-radius: 0px !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0px;
  background-color: var(--background-color);
}

.categories_div_main  .categories_ul_list{
  list-style: none;
  padding: 0px;  
}


.categories_div_main .categories_ul_list > li{
  border-bottom: 1px dashed var(--background-color2);
  padding: 4px 15px;
  transition: .5s ease-in-out;
}

.categories_div_main .categories_ul_list > li > a{
  color: var(--primary-color);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  transition: .5s ease-in-out;
}


.categories_div_main .categories_ul_list > li > a:hover{
  transition: .5s ease-in-out;
  margin: 0px 5px;
  color: var(--black-color2);
}

.sidebar_profile_div_main{
  border-bottom: 1px solid var(--background-color2);
  padding-top: 5px;
  padding-bottom: 7px;
}

.sidebar_profile_div_main > ul{
  list-style: none;
  padding: 0px;
  margin-bottom: 0px !important;
}

.sidebar_profile_div_main > ul > li{
  padding: 0px 15px;
  transition: .5s ease-in-out;
  line-height: 28px;
}

.sidebar_profile_div_main > ul > li > a{
  color: var(--black-color2);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  transition: .5s ease-in-out; 
}

.sidebar_profile_div_main > ul > li > a:hover{
  transition: .5s ease-in-out;
  margin: 0px 5px;
  color: var(--black-color2);
}

/*  */
.sidebar_terms_div_main{
  border-bottom: 1px solid var(--background-color2);
  padding-top: 8px;
  padding-bottom: 5px;
  background-color: var(--background-color);
}

.sidebar_terms_div_main > ul{
  list-style: none;
  padding: 0px;
  margin-bottom: 0px !important;
}

.sidebar_terms_div_main > ul > li{
  padding: 0px 15px;
  transition: .5s ease-in-out;
  line-height: 28px;
}

.sidebar_terms_div_main > ul > li > a{
  color: var(--black-color2);
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  transition: .5s ease-in-out; 
}

.sidebar_terms_div_main > ul > li > a:hover{
  transition: .5s ease-in-out;
  margin: 0px 5px;
  color: var(--black-color2);
}

/* mobile navbar css end */


/* ================ media  991px =============== */
@media (max-width:991px){

  .container{
    padding: 0px 15px !important;
  }

  .left_topbar_div{
      text-align: center !important;
  }

  .right_topbar_div{
      display: none !important;
  }

  .logo_section{
      display: none !important;
  }
  
.phone_view_icons_div{
  display: block !important;
}

  .navbar-brand{
      display: block !important;
        }

  .navbar-brand img{
      width: 160px;
  }
}
/* ================ media  991px end=============== */
.dropdown-menu {
  border-radius: 0;
  outline: none !important;
  border: none;
  background-color: white;
  padding: 0 !important;
  
  
}
.dropdown-menu > li > a {
 font-size: 14px;
  
}