@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Lora', serif !important;
  background-color: #fff;
}
.container {
  max-width: 100%;
  margin: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
}
a {
  text-decoration: none;
  color: #000000;
  text-transform: capitalize;
}
ul {
  list-style-type: none;
}
button {
  margin-top: 5px;
  margin-left: 92px;
  border-radius: 7px;
  padding: 7px 20px;
  background: #ef7c01;
  border: rgb(0, 0, 0);
  color: #ffffff;
  cursor: pointer;
  /* font-weight: bold; */
}
@media (max-width: 768px) {
  button {
    margin-left: 96px;
  }
}
@media (max-width: 576px) {
  button {
    margin-left: 87px;
  }
}
button i {
  margin-right: 5px;
}
.heading {
  text-align: center;
  width: 60%;
  margin: auto;
}
.heading h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 25px;
}
.heading p {
  color: #ef7c01;

  margin-right: -35px;
}

@media (max-width: 768px) {
  .heading p {
    margin-right: -7px;
  }
}

textarea,
input {
  border: none;
  outline: none;
  background: none;
}
.background {
  padding: 15px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}
label {
  color: #2d3954;
  font-size: 14px;
}
.mtop {
  margin-top: 7px;
}
h4 {
  font-weight: 500;
  margin-top: 20px;
}
.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}
.padding {
  padding: 10px 0;
  background-color: #dedede1a;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
}
.btn2 {
  border-radius: 50px;
  font-size: 18px;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.btn3 {
  background: #ff6922;
  border-radius: 50px;
}
.btn4 {
  background-color: #000;
  color: #fff;
}
.btn5 {
  border-radius: 50px;
    padding: 10px 10px;
    color: #6f7380;
    font-size: 16px;
    font-weight: 400;
    border: 7px solid #27ae601f;
    background: #fff;
}
.back {
  height: 40vh;
  position: relative;
}
.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}
.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(17, 40, 72, 0.629);
  z-index: -1;
}
.back .container {
  color: #fff;
  padding: 71px 0;
  text-align: center;
}
.back h1 {
  font-size: 40px;
  font-weight: 500;
}
.mb {
  margin-bottom: 10px;
}
:root {
  --primary-color: #9fa59c;
  --secondry-color:#e6e6e6 ;
  --third-color: #25697e;
  --four-color: #D2B48C;
  --five-color: #E5D3B3;
  --white-color: #fff;
  --black-color: #000;
  --black-color2: #141615;
  --black-color3: #202020;
  --black-color4: #212529;
  --lightgray-color: #e7e6e6;
  --gray-color: #a1a1a1;
  --gray-color2: #a5a4a4;
  --facebook-color: #3b5998;
  --instagram-color: #962fbf;
  --youtube-color: #c4302b;
  --background-color: #fafafa;
}
@media screen and (max-width: 800px) {
  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 100%;
  }
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}
