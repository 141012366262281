.contact form div {
  display: flex;
  
}

.contact textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 77px;
  border-radius: 5px;
  margin-bottom: 10px;
  
}
.contact input {
  margin-bottom: 20px;
  /* margin-right: 10px; */
}
.contact form {
  padding: 15px;
  margin-top: 12px;
  padding-top: 8px;
}
@media screen and (max-width: 768px) {
  .contact h4 {
    width: 100%;
    margin-bottom: 30px;
  }
  .contact form div {
    flex-direction: column;
  }
  .contact input {
    width: 100%;
  }
}
.contact-info {
  background-color: rgba(128, 128, 120, 0.071);
  align-items: center;
  margin-top: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add this line */
}
@media (max-width: 768px) {
  .contact .container {
    flex-direction: column;
    align-items: center;
  }
  .contact .col-lg-6 {
    width: 100%;
    text-align: center;
  }
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
}

form h4 {
  margin-bottom: -5px !important;
  margin-top: 5px !important;
}

form div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

form input, form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form button {
  padding: 10px 20px;
  margin-left:7px;
  border: none;
  /* background: linear-gradient(45deg, #1556fc, #04a9fe); */
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

form button:hover {
  background-color: #0056b3;
}
.contact-info {
  margin-bottom: 30px;
}

.contact-info h5 {
  padding-top: 10px;
  padding-left: 35px;
  padding-bottom: 7px;
}

.contact-info p {
  margin-left: 60px;
}

.contact-info .icon {
  color: orange;
}

.contact-info .email {
  padding-left: 10px;
}

.contact-info .email p {
  margin-left: 20px;
}

.contact-info .address {
  padding-left: 10px;
}

.contact-info .address p {
  margin-left: 20px;
}
.phone-number {
  margin-bottom: 6px;
}
.email-link {
  margin-bottom: 6px;
}
.address-info {
  margin-bottom: 6px;
}



/* Mobile view */
@media (max-width: 768px) {
  .contact-info h5 {
    padding-left: 0px;
    padding-bottom: 0px;
  }

  .contact-info p {
    margin-left: 0px;
  }

  .contact-info .email p,
  .contact-info .address p {
    margin-left: 0px;
  }
  .phone-number {
    margin-bottom: 0px;
  }
  .email-link {
    margin-bottom: 0px;
  }
  .address-info {
    margin-bottom: 0px;
  }
}
a {
  color: rgb(0 0 0);
}