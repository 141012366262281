.product-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.product-image-container, .product-info {
    flex: 1;
    overflow: hidden; /* Add overflow hidden */
}
.product-info-text {
    font-size: 20px; /* Set the font size to 26px */
    margin-left: 20px; /* Add left margin */
    margin-top: 20px; /* Add top margin */
}
.product-image {
    width: 100%;
    height: 450px;
    object-fit: cover;
    padding-left: 20px;
    padding-right: 20px;
    /* padding-top: 20px; */
    padding-bottom: 20px;

}

@media screen and (max-width: 768px) {
    .product-details {
        flex-direction: column;
    }

    .product-image-container, .product-info {
        padding: 0;
        /* margin-bottom: 20px; */
    }
}
@media screen and (max-width: 768px) {
    .product-image {
        height: 100%;
        padding-top: 0px;
    }
}
@media (max-width: 768px) {
    .product-details {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
  .product-details {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

.product-info {
    width: 100%;
    border-collapse: collapse;
}

.product-info td, .product-info th {
    border: 1px solid #ddd;
    padding: 8px;
}

.product-info tr:nth-child(even) {
    background-color: #f2f2f2;
}