#wp {
    position: fixed;
    bottom: 0px;
    right:0px;
    z-index: 99;
    display:none;
 }
 
 #wp a {
    color: #fff;
    border-top: 1px solid #0dc143;
    display: block;
    height: 40px;
    position: relative;
    text-align: center;
    line-height: 40px;
    width: 200px;
    margin-bottom: 1px;
 }
 
 #wp a:hover>span {
    visibility: visible;
    right: 41px;
    opacity: 1;
 } 
 
 #wp a span {
    line-height: 40px;
    right: 60px;
    position: absolute;
    text-align: center;
    width: 120px;
    visibility: hidden;
    transition-duration: 0.5s;
    z-index: 1;
    opacity: 0;
 }
 
 #cll {
    position: fixed;
    bottom: 0px;
    left:0px;
    z-index: 99;
    display:none;
 }
 
 #cll a {
    color: #fff;
    border-top: 1px solid #212121;
    display: block;
    height: 40px;
    position: relative;
    text-align: center;
    line-height: 40px;
    width: 200px;
    margin-bottom: 1px;
 }
 
 #cll a:hover>span {
    visibility: visible;
    right: 41px;
    opacity: 1;
 } 
 
 #cll a span {
    line-height: 40px;
    right: 60px;
    position: absolute;
    text-align: center;
    width: 120px;
    visibility: hidden;
    transition-duration: 0.5s;
    z-index: 1;
    opacity: 0;
 }
 
 #fixed-social1 {
    position: fixed;
    top: 250px;
    left:0px;
    z-index: 99;
 }
 
 #fixed-social1 a {
    color: #fff;
    height: 40px;
    position: relative;
    text-align: center;
    line-height: 40px;
    width: 40px;
    margin-bottom: 1px;
    border-radius:0px 50px 50px 0px;
 }
 
 #fixed-social1 a:hover>span {
    visibility: visible;
    left: 41px;
    opacity: 1;
 } 
 
 #fixed-social1 a span {
    line-height: 40px;
    left: 60px;
    position: absolute;
    text-align: center;
    width: 120px;
    visibility: hidden;
    transition-duration: 0.5s;
    z-index: 1;
    opacity: 0;
    border-radius:50px 50px 50px 50px;
 }
 
 .whatapp {
    background-color: #f07c00;
   
 }
 
 .whatapp span {
    background-color: #0dc143;
 }
 
 .insta {
    background-color: #605e5e;
 }
 
 .insta span {
    background-color: #f07c00;
 }
 
 @media only screen and (max-width: 767px) {
    #wp {
       display: block;
    }
    #cll {
       display: block;
    }
 }
 