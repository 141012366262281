
.navbar-nav > .dropdown > .dropdown-menu {
    padding: 0px !important;
    border-radius: 0px !important;
    outline: none !important;
    border: none !important;
    width: 250px;
  }
  
  .navbar-nav > .dropdown:hover > .dropdown-menu {
    display: block;
    border-top: 3px solid var(--primary-color) !important;
  }
  
  .navbar-nav > .dropdown > .dropdown-menu > li {
    border-bottom: 1px dashed var(--lightgray-color);
    transition: 0.4s ease-in-out;
    text-transform: capitalize;
    line-height: 28px;
  }
  
  .navbar-nav > .dropdown > .dropdown-menu > li > a {
    font-size: 15px;
    font-weight: 500;
    transition: 0.4s ease-in-out;
    text-transform: capitalize;
    color: var(--black-color4);
  
  }
  
  .navbar-nav > .dropdown > .dropdown-menu > li > a:hover {
  
    transition: 0.4s ease-in-out;
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  
  .mkp-submenu i {
    float: right !important;
    font-size: 15px;
    line-height: 28px;
  }
  
  .mkp-submenu:hover > ul.dropdown-menu {
    display: block !important;
    border-top: 3px solid var(--third-color) !important;
   }
  
  .mkp-submenu {
    position: relative;
  }
  
  .mkp-submenu > .dropdown-menu {
    top: 0;
    left: 100% !important;
    margin-top: -3px;
    background-color: #fff !important;
    padding: 0;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    border: none !important;
  }
  
  .mkp-submenu > .dropdown-menu li > a {
    background-color: transparent !important;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    border-bottom: 1px dashed lightgray;
    transition: 0.3s ease-in-out;
  }
  
  .mkp-submenu > .dropdown-menu li > a:hover {
    background-color: var(--third-color) !important;
    color: var(--white-color) !important;
    text-transform: capitalize;
    letter-spacing: 0.2px;
    border-bottom: 1px dashed lightgray;
    transition: 0.3s ease-in-out;
  }
  