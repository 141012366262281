
/* about us css start */

.about_us_section{
  padding: 30px 0;
  position: relative;
}

.sec_img_overlay{
  position: absolute;
  right: 17%;
  bottom: 7%;
  z-index: -99999;
  opacity: .6;
}

.sec_img_overlay2{
  position: absolute;
  right: 0;
  bottom: 6%;
  z-index: -99999;
  opacity: .6;
}

.sec_img_overlay2 img{
  width: 250px;
}

.about_head_title{
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.about_content_div h1{
  text-transform: capitalize;
  font-size: 36px;
  font-weight: 800;
  position: relative;
  /* display: inline-block;
  padding: 0px 30px; */
  color: var(--primary-color);
  margin-bottom: 0px;
}

/* 
.about_head_title h1:before {
  content: "";
  border-top: solid 3px var(--secondry-color);
  width: 10%;
  height: 2px;
  position: absolute;
  top: 45%;
  z-index: 1;
  right: 30%;
}


.about_head_title h1:after {
  content: "";
  border-top: solid 3px var(--primary-color);
  width: 7%;
  height: 2px;
  position: absolute;
  top: 25%;
  z-index: 1;
  right: 30%;
} */


.about_head_title h1 span{
  color: var(--primary-color);
background-image: -webkit-linear-gradient(92deg,  #141615, #47143d, #47143d, #141615, #141615);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
-webkit-animation: hue 10s infinite linear;
}

@-webkit-keyframes hue {
from {
  -webkit-filter: hue-rotate(0deg);
}
to {
  -webkit-filter: hue-rotate(-360deg);
}
}

.about_head_title h1 img{
  width: 60px;
  text-align: center;
  align-items: center;
  margin-top: -12px;
  animation: mover 1s infinite  alternate;
}

@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

.about_head_title p{
  text-align: center;
  font-weight: 500;
}

.about_head_title h2{
  color: var(--primary-color);
  font-size: 22px;
   font-weight: 700;
  text-transform: uppercase;
}

.about_head_title h2 span{
  color: var(--secondry-color);
}

.about_img_main_div{
  position: relative;
  transition: .5s ease-in-out;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;

}

.about_vd_div{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding:10px 10px 3px 10px !important;
  background-color: var(--white-color);

}

.about_img_box{
  position: relative;
  transition: .5s ease-in-out;
  text-align: center;
  width: 100%;
}

.about_img_box img{
  transition: .5s ease-in-out;
  text-align: center;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  background-color: var(--white-color);
  padding: 6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.about_overlay_box{
  position: absolute;
  top: 50%;
  left: 30%;
}

.about_overlay_box img{
  width: 200px;
  background-color: var(--secondry-color);
  padding: 6px;
  animation: mover 1s infinite  alternate;
  border-radius: 0px !important;
}

.about_content_div h3{
  text-transform: capitalize;
  font-size: 23px;
  line-height: 30px;
  font-weight: 800;
  color: var(--black-color);
  line-height: 33px;
  margin-bottom: 5px !important;
}

.about_content_div h4{
  text-transform: capitalize;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: var(--primary-color); 
 
}

.img_about_div {
  margin-bottom: 30px;
}

.nst_img_div_ab{
  margin-bottom: 20px;
}

.img_about_div  a:nth-child(2){
 margin: 0px 20px;
}

.img_about_div img{
  filter: invert(100%);
  width: 50px;
}

.about_content_div p{
  font-size: 16px;
  line-height: 29px;
  font-weight: 500;
  padding: 0px 0px;
}
.about_btn_div {
  margin-top: 10px;
}
.about_btn_div img{
  width: 30px;
  animation: mover 1s infinite  alternate;
}

.about_btn_div{
  margin-top: 20px;
}
.about_btn_div a{
  text-transform: capitalize;
  position: relative;
  font-weight: 800;
  font-size: 16px;
}
.about_btn_div a{
  text-transform: capitalize;
  color: var(--white-color);
  padding: 12px 30px 12px 30px;
  background-color: var(--black-color4);
  border: 2px solid var(--white-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.about_btn_div a:hover{

  background-color: var(--primary-color);
}


.about_content_div  ul{
  list-style: none;
  padding: 0px 2px;
}


.about_content_div li{
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
}

.about_content_div b{
  color: var(--primary-color);
}


/* 
.about_btn_div a:before {
  content: "";
  border-top: solid 3px var(--secondry-color);
  width: 80%;
  height: 2px;
  position: absolute;
  top: 50%;
  z-index: 1;
  right: -100%;
}


.about_btn_div a:after {
  content: "";
  border-top: solid 3px var(--primary-color);
  width: 50%;
  height: 2px;
  position: absolute;
  top: 10%;
  z-index: 1;
  right: -100%;
} */


/* about us css end */
.about_vd_div{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding:10px 10px 3px 10px !important;
  background-color: var(--white-color);

}
/* why us css */
.why_choose_us_section{
  padding-top: 20px;
      padding-bottom: 30px;
      background-color: #faf8f8;
      /* margin-bottom: 30px; */
}

.why_choose_txt h4{
  font-size: 25px;
  font-weight: 600;
  text-transform: capitalize;
}

.why_choose_txt{
  margin-bottom: 20px;
}

.why_choose_txt p b{
  color: var(--secondry-color);
  font-weight: 500;
  font-size: 15px;
}

.why_choose_us_div_flx{
  margin-bottom: 10px;
}

.why_choose_us_div_flx .flex-shrink-0 i{
  background-color: var(--secondry-color);
  color: var(--white-color);
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  border-radius: 100px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
}

.why_choose_us_div_flx{
  margin-top: 10px;
}

.why_us_div_txt_div p{
     font-size: 14px;
      color: var(--black-color3);
      line-height: 26px;
      letter-spacing: .5px;
      font-weight: 400 !important;
      margin-bottom: 7px !important;
}

.why_us_div_txt_div h5{
  color: var(--black-color);
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
}

.certifcat_btn_div{
  margin-top: 20px;
}

.certifcat_btn_div a{
  background-color: var(--primary-color);
  padding: 10px 20px 10px 20px;
  color: var(--white-color);
  border-radius: 100px;
  font-size: 14px;
  letter-spacing: .4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: .5s ease-in-out;
}

.certifcat_btn_div a:hover{
  background-color: var(--black-color3);
}

.why_us_img_div{
  text-align: center;
  position: relative;
}

.why_us_img_div img{
  margin: auto;
  text-align: center;
  display: block;
}

.why_overlay_div{
position: absolute;
bottom: -30%;
left: 0;
}

.why_overlay_div img{
  width: 300px;
}

.why_overlay_div2 {
  position: absolute;
  top: -25%;
  right: 25%;
}

.why_overlay_div2 img {
  width: 200px;
}

/* end */